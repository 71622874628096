import useUser from '~/contexts/User/useUser';

type SearchCardHeaderProps = {
  id: string;
  itemNumber?: number;
  title: JSX.Element | string;
  badge?: JSX.Element;
  buttons?: JSX.Element;
  titleTestId?: string;
};
export default function SearchCardHeader({
  id,
  itemNumber,
  title,
  badge,
  buttons,
  titleTestId
}: SearchCardHeaderProps): JSX.Element {
  const { isAdmin } = useUser();

  return (
    <div className="card-header p-2">
      <div className="d-flex justify-content-between">
        <div className="h4 my-auto" data-testid={titleTestId}>
          {title}
        </div>
        <div className="card-badges text-end">
          {isAdmin && <span className="badge bg-secondary ms-1">{id}</span>}
          {!!itemNumber && <span className="badge bg-secondary ms-1">#{itemNumber}</span>}
          {badge}
          {buttons}
        </div>
      </div>
    </div>
  );
}
