import classNames from 'classnames';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import LoadingComponent from '~/features/shared/components/LoadingComponent';
import PageCountDisplay from './PageCountDisplay';
import TotalDisplay from './TotalDisplay';
import FadeIn from '~/features/shared/components/FadeIn';

type CardListProps = {
  className: string;
  renderItem: (data: any) => JSX.Element;
  data: any[];
  totalItems: number;
  pageIndex: number;
  pageSize: number;
  loading: boolean;
  refetching?: boolean;
  showTotalDisplay?: boolean;
  error?: any;
  rowClassName?: string;
  colClassName?: string;
  keyPropName?: string;
};

export default function CardList({
  className,
  renderItem,
  data,
  loading,
  refetching = false,
  totalItems,
  pageIndex,
  pageSize,
  error,
  showTotalDisplay = true,
  rowClassName = 'g-2',
  colClassName = 'col-md-4',
  keyPropName = 'id'
}: CardListProps) {
  const nodeRef = useRef(null);
  return (
    <div className={classNames('card-list', className)}>
      {refetching && (
        <CSSTransition
          nodeRef={nodeRef}
          in={refetching}
          timeout={300}
          classNames="card-list-loader"
          key="loader"
        >
          <div ref={nodeRef} className="card-list-loader progress rounded-0">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
              style={{ width: '100%' }}
            />
          </div>
        </CSSTransition>
      )}
      {loading && <LoadingComponent extraPadding />}
      {error && <div className="alert alert-danger">{error.toString()}</div>}
      {!loading && (
        <>
          {showTotalDisplay && (
            <div>
              <TotalDisplay totalItems={totalItems} />
            </div>
          )}
          <div className={classNames('row mb-2', rowClassName)}>
            {data.map((x) => (
              <div key={x[keyPropName]} className={colClassName}>
                <FadeIn>{renderItem(x)}</FadeIn>
              </div>
            ))}
          </div>
          {totalItems > 0 && (
            <PageCountDisplay pageIndex={pageIndex} pageSize={pageSize} totalItems={totalItems} />
          )}
        </>
      )}
      <style jsx>{`
        .card-list {
          clear: both;
        }
        .card-list :global(.card) {
          display: block;
          position: inherit;
        }
      `}</style>
    </div>
  );
}
